<template>
  <div class="join-us">

    <h1>Join Us</h1>

    <h4>How do you become a member?</h4>
    <ul>
      <li>You must be part of our 'common bond' i.e. live or work in Tameside and Glossop</li>
      <li>Complete an application form and supply 2 forms of ID</li>
      <li>&pound;2 to join – life membership</li>
    </ul>

    <h4>How to Save</h4>
    <ul>
      <li>Standing Order – complete a form and take it to your bank</li>
      <li>Payroll Deduction – direct deduction straight from your salary</li>
    </ul>

    <h4>Ways To Save</h4>
    <p>
      By paying a little into your Savings Account each week or each month, you
      will be surprised at how much you can save!
    </p>
    <p>Joining Cash Box Credit Union is easy ……</p>

    <p>
      <a
          class="btn btn-lg btn-success"
          href="https://cashbox.cuaccount.com/join/"
          target="_blank"
      >Join Us</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: 'JoinUs',
  metaInfo() {
    return {
      title: 'Join Us | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
